.Task {
    width: 50%;
    height: 100%;
    display: inline-block;
    margin:0;
    padding: 0;
    color: #2e3133;
}

.Task:hover {
    background-color: #c0dd5d;
    color: #fff;
}

.Task__Name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: "Helvetica";
    font-size: 64px;
    margin: 0;
}